import React from 'react';
import { styled } from '../../../stitches.config';
import { TinaField } from '../../../shared/types';
import ExportedImage from 'next-image-export-optimizer';
import { Headline } from '../../molecules/Headline/Headline';
import { Button } from '../../atoms/Button/Button';
import { useRouter } from 'next/router';
import { GridLargeItemLayout, GridLayout } from '../../../utils/styles';
import { Flex } from 'components/atoms/Flex';
import { Spacer } from 'components/atoms/Spacer';
import { useEditState } from 'tinacms/dist/react';
import { removeAssetTinaIoFromImageUrl } from '../../../shared/Helper';

export type Cover7Props = {
  headline?: string | null;
  subHeadline?: string | null;
  buttonHref?: string | null;
  buttonLabel?: string | null;
  imageSrc?: string | null;
  imageAlt?: string | null;
  imagePosition?: string | null;
} & TinaField;

const ImageLayout = styled('div', {
  width: '100%',
  position: 'relative',
  minHeight: '200px',
  '@tabletAndUp': {
    position: 'absolute',
    minHeight: '600px',
    left: 0,
  },
});

export const Cover7: React.FC<Cover7Props> = ({
  headline,
  subHeadline,
  buttonHref,
  buttonLabel,
  imageSrc,
  imageAlt,
  imagePosition,
}) => {
  const router = useRouter();
  const { edit: isInAdminEditMode } = useEditState();
  const imageSrcWithoutAssetTinaIo = removeAssetTinaIoFromImageUrl(
    imageSrc ?? '',
  );

  return (
    <>
      <Flex
        css={{
          backgroundColor: '$skin-white-smoke',
          flexDirection: 'column',
          paddingBlock: '$spacing-5x',
          paddingInline: '$spacingMainMobile',
          '@tabletAndUp': {
            paddingBlock: '$spacing-7x',
            position: 'relative',
            minHeight: '600px',
          },
        }}
      >
        <Flex
          css={{
            flexDirection: 'column',
            maxWidth: '$laptop',
            width: '100%',
            margin: 'auto',
            '@tabletAndUp': {
              justifyContent: 'center',
            },
          }}
        >
          {imageSrc && imageAlt && isInAdminEditMode && (
            <>
              <ImageLayout>
                <ExportedImage
                  src={imageSrc}
                  alt={imageAlt}
                  layout="fill"
                  objectFit="cover"
                  objectPosition={imagePosition ? imagePosition : undefined}
                />
              </ImageLayout>
              <Spacer size="$spacing-3x" />
            </>
          )}
          {imageSrc && imageAlt && !isInAdminEditMode && (
            <>
              <ImageLayout>
                <ExportedImage
                  src={imageSrcWithoutAssetTinaIo}
                  alt={imageAlt}
                  layout="fill"
                  objectFit="cover"
                  objectPosition={imagePosition ? imagePosition : undefined}
                />
              </ImageLayout>
              <Spacer size="$spacing-3x" />
            </>
          )}
          <Flex
            css={{
              flexDirection: 'column',
              zIndex: '$higher',
              '@tabletAndUp': {
                maxWidth: 'calc($textContent - 80px)',
              },
            }}
          >
            <Headline headline={headline} subheadline={subHeadline} />
            <Spacer size="$spacing-4x" />
            <Button
              fullWidthOnMobile
              onClick={() => {
                if (buttonHref) {
                  router.push(buttonHref);
                }
              }}
            >
              {buttonLabel}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
