import { ReactNode, memo } from 'react';
import { Container } from './LayoutFlowItem.styles';
import type { VariantProps } from '@stitches/react';
import { CSS } from '@stitches/react';

function LayoutFlowItemPureComponent({
  children,
  css,
  size = 'full',
}: {
  children?: ReactNode;
  size?: VariantProps<typeof Container>['size'];
  css?: CSS;
}) {
  return (
    <Container size={size} css={css}>
      {children}
    </Container>
  );
}

export const LayoutFlowItem = memo(LayoutFlowItemPureComponent);
