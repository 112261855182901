import { constants } from './constants';

export const FitContentStyle = [
  { width: 'fit-content' },
  { width: '-webkit-fit-content' },
  { width: '-moz-fit-content' },
];

export const PHONE_REG_EXP = /\*{0,16}/;

type RecursivelyReplaceNullWithUndefined<T> = T extends null
  ? undefined // Note: Add interfaces here of all GraphQL scalars that will be transformed into an object
  : T extends Date
  ? T
  : {
      [K in keyof T]: T[K] extends (infer U)[]
        ? RecursivelyReplaceNullWithUndefined<U>[]
        : RecursivelyReplaceNullWithUndefined<T[K]>;
    };

/**
 * Recursively replaces all nulls with undefineds.
 * Skips object classes (that have a `.__proto__.constructor`).
 *
 * Unfortunately, until https://github.com/apollographql/apollo-client/issues/2412
 * gets solved at some point,
 * this is the only workaround to prevent `null`s going into the codebase,
 * if it's connected to a Apollo server/client.
 */
export function replaceNullsWithUndefineds<T>(
  obj: T,
): RecursivelyReplaceNullWithUndefined<T> {
  const newObj: any = {};
  Object.keys(obj).forEach((k) => {
    const v: any = (obj as any)[k];
    newObj[k as keyof T] =
      v === null
        ? undefined
        : // eslint-disable-next-line no-proto
        v && typeof v === 'object' && v.__proto__.constructor === Object
        ? replaceNullsWithUndefineds(v)
        : v;
  });
  return newObj;
}

export function removeAssetTinaIoFromImageUrl(url: string) {
  const { TINA_ASSETS_STRING_IN_URL } = constants;

  const includesAssetsTinaIo = url.includes(TINA_ASSETS_STRING_IN_URL);

  if (!includesAssetsTinaIo) {
    return url;
  }

  const replaceTinaAssetIoString = `${TINA_ASSETS_STRING_IN_URL}/${process.env.NEXT_PUBLIC_TINA_CLIENT_ID}`;

  const urlWithoutAssetTinaIo = url.replace(replaceTinaAssetIoString, '');

  // relative url case
  if (urlWithoutAssetTinaIo?.[0] === '/') {
    return `/uploads${encodeURI(urlWithoutAssetTinaIo)}`;
  }

  // this is a special case when the image src comes from an external source
  // for example an external source could be "cloudinary"
  return urlWithoutAssetTinaIo;
}
