import React from 'react';
import { styled } from '../../../stitches.config';
import { TinaField } from '../../../shared/types';
import { Section } from 'react-headings';
import { Heading } from 'shared/Typography';
import { Spacer } from 'components/atoms/Spacer';

export type HeadingProps = {
  headline?: string | null;
  subheadline?: string | null;
} & TinaField;

export const Headline: React.FC<HeadingProps> = ({
  headline = '',
  subheadline = '',
}) => {
  return (
    <>
      <Section
        component={
          <Heading
            variant={{ '@initial': 'h1', '@tabletAndUp': 'h1TabletAndUp' }}
          >
            {headline}
          </Heading>
        }
      >
        <Spacer size="$spacing-3x" />
        <Heading
          variant={{ '@initial': 'h4', '@tabletAndUp': 'h4TabletAndUp' }}
        >
          {subheadline}
        </Heading>
      </Section>
    </>
  );
};
