import type { CSS } from '@stitches/react';
import { Container } from './LayoutImage.styles';
import ExportedImage, { ExportedImageProps } from 'next-image-export-optimizer';
import { memo } from 'react';
import { useEditState } from 'tinacms/dist/react';
import { removeAssetTinaIoFromImageUrl } from '../../../shared/Helper';

function LayoutImagePureComponent({
  aspectRatio = 1,
  imageAlt,
  imageUrl,
  imagePosition,
  sizes,
  layout = 'fill',
  objectFit = 'cover',
}: {
  aspectRatio?: CSS['aspectRatio'];

  imageAlt?: string | null;
  imageUrl?: string | null;
  imagePosition?: string | null;
  sizes?: ExportedImageProps['sizes'];
  layout?: ExportedImageProps['layout'];
  objectFit?: ExportedImageProps['objectFit'];
}) {
  const { edit: isInAdminEditMode } = useEditState();

  if (isInAdminEditMode) {
    return (
      <Container css={{ aspectRatio }}>
        {imageUrl && (
          <ExportedImage
            alt={imageAlt ?? ''}
            src={imageUrl}
            layout={layout}
            objectPosition={imagePosition ?? undefined}
            objectFit={objectFit}
            sizes={sizes}
          />
        )}
      </Container>
    );
  }

  const imageSrcWithoutAssetTinaIo = removeAssetTinaIoFromImageUrl(
    imageUrl ?? '',
  );

  return (
    <Container css={{ aspectRatio }}>
      {imageUrl && (
        <ExportedImage
          alt={imageAlt ?? ''}
          src={imageSrcWithoutAssetTinaIo}
          layout={layout}
          objectPosition={imagePosition ?? undefined}
          objectFit={objectFit}
          sizes={sizes}
        />
      )}
    </Container>
  );
}

export const LayoutImage = memo(LayoutImagePureComponent);
