import { Container, Content } from './LayoutContainer.styles';
import { ReactNode, memo } from 'react';
import type { CSS } from '@stitches/react';

function LayoutContainerPureComponent({
  children,
  backgroundColor,
  maxContentWidth,
  paddingBlock,
  paddingInline,
}: {
  children?: ReactNode;
  backgroundColor?: CSS['backgroundColor'];
  maxContentWidth?: CSS['maxWidth'];
  paddingBlock?: CSS['paddingBlock'];
  paddingInline?: CSS['paddingInline'];
}) {
  return (
    <Container css={{ backgroundColor, paddingBlock, paddingInline }}>
      <Content css={{ maxWidth: maxContentWidth }}>{children}</Content>
    </Container>
  );
}

export const LayoutContainer = memo(LayoutContainerPureComponent);
