import { styled } from '../../../stitches.config';
import type * as Stitches from '@stitches/react';
import { ButtonStyle } from '../../../shared/Typography';
import { FitContentStyle } from '../../../shared/Helper';

export const Button = styled(
  'button',
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    padding: '$spacing-2x $spacing-4x',

    ...ButtonStyle,
    borderRadius: '$rounded',
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',

    defaultVariants: {
      type: 'primary',
    },

    variants: {
      type: {
        primary: {
          backgroundColor: '$skin-accent1',
          color: '$skin-white',

          '&:hover': {
            backgroundColor: '$skin-accent1-shade1',
          },
        },
        secondary: {
          border:
            '$borderWidths$border-width-medium solid $colors$skin-accent1',
          backgroundColor: '$skin-white',
          color: '$skin-black',

          '&:hover': {
            borderColor: '$skin-accent1-shade1',
          },
        },
      },
      fullWidthOnMobile: {
        true: {
          width: '100%',
          '@tabletAndUp': {
            width: 'fit-content',
          },
        },
      },
      fullWidth: {
        true: {
          width: '100%',
        },
      },
    },
  },
  //@ts-ignore @see https://discord.com/channels/752614004387610674/752795957674115092/866993101414465546
  ...FitContentStyle,
);
