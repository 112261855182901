import { styled } from '../../../stitches.config';

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

export const Content = styled('div', {
  width: '100%',
  '> p': {
    marginBottom: '1rem',
  },
});
