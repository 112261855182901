import { Button } from '../components/atoms/Button/Button';
import { Card } from '../components/atoms/Card';
import { Cover7 } from '../components/organisms/Cover7/Cover7';
import { LayoutContainer } from '../components/atoms/LayoutContainer';
import { LayoutFlow } from '../components/atoms/LayoutFlow';
import { LayoutFlowItem } from '../components/atoms/LayoutFlowItem';
import { LayoutIcons } from '../components/atoms/LayoutIcons';
import { LayoutImage } from '../components/atoms/LayoutImage';
import { LayoutText } from '../components/atoms/LayoutText';
import Link from 'next/link';
import React from 'react';
import { Spacer } from '../components/atoms/Spacer';

import { useTina } from 'tinacms/dist/react';
import client from '../tina/__generated__/client';
import type { Exact, MainQuery } from '../tina/__generated__/types';
import { Flex } from '../components/atoms/Flex';
import { Section } from 'react-headings';
import { Heading } from '../shared/Typography';
import { MarkdownWithTypo } from '../components/organisms/MarkdownWithTypo/MarkdownWithTypo';
import { ContentCenter14 } from '../components/organisms/ContentCenter14/ContentCenter14';
import { config } from '../stitches.config';
import { NextSeo } from 'next-seo';
import { replaceNullsWithUndefineds } from '../shared/Helper';
import { NextSeoProps } from 'next-seo/lib/types';

export default function Home(props: {
  query: string;
  variables: Exact<{ relativePath: string }>;
  data: MainQuery;
}) {
  const { data } = useTina({
    query: props.query,
    variables: props.variables,
    data: props.data,
  });

  return (
    <>
      <NextSeo {...(data.main.seo as NextSeoProps)} />
      <Cover7 {...data.main.cover} />
      <Flex
        css={{
          flexDirection: 'column',
          paddingBlock: '$spacing-5x',
          paddingInline: '$spacingMainMobile',
          '@tabletAndUp': {
            paddingBlock: '$spacing-7x',
          },
        }}
      >
        <Flex
          css={{
            flexDirection: 'column',
            maxWidth: '$textContent',
            margin: 'auto',
          }}
        >
          <MarkdownWithTypo content={data.main.welcomeText} />
        </Flex>
      </Flex>

      <Flex
        css={{
          backgroundColor: '$skin-white-smoke',
          flexDirection: 'column',
          paddingBlock: '$spacing-5x',
          paddingInline: '$spacingMainMobile',
          '@tabletAndUp': {
            paddingBlock: '$spacing-7x',
          },
          '@laptopAndUp': {
            display: 'grid', // Necessary for Safari
          },
        }}
      >
        <Flex
          css={{
            flexDirection: 'column',
            maxWidth: '$laptop',
            margin: 'auto',
            '@laptopAndUp': {
              display: 'grid', // Necessary for Safari
            },
          }}
        >
          <Section
            component={
              <Heading
                variant={{ '@initial': 'h2', '@tabletAndUp': 'h2TabletAndUp' }}
              >
                {data.main.servicesHeadline}
              </Heading>
            }
          >
            <Flex
              css={{
                alignItems: 'center',
                flexDirection: 'column',
                gap: '$spacing-5x',
                paddingBlockStart: '$spacing-5x',
                '@tabletAndUp': {
                  display: 'grid',
                  gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
                  alignItems: 'stretch',
                  paddingBlockStart: '$spacing-10x',
                },
                '@laptopAndUp': {
                  display: 'grid',
                  gridTemplateColumns:
                    'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
                },
              }}
            >
              {data.main.cards?.map((card, index) => (
                <Card
                  linkUrl={card?.link}
                  scroll={false}
                  key={'cardIndex' + index}
                >
                  <LayoutImage
                    key={'cardIndexImage' + index}
                    aspectRatio={41 / 25}
                    imageAlt={card?.imageAlt}
                    imageUrl={card?.imageSrc}
                    imagePosition={card?.imagePosition}
                    sizes={`${config.media.tabletAndUp} 15vw, 100vw`}
                  />
                  <LayoutContainer
                    paddingBlock="$spacing-4x"
                    paddingInline="$spacing-4x"
                  >
                    <LayoutFlow align="center" gap="$spacing-4x" wrap="wrap">
                      <LayoutFlowItem>
                        <Heading
                          color="skinAccent1Shade1"
                          variant="h5"
                          align="center"
                        >
                          {card?.headline}
                        </Heading>
                      </LayoutFlowItem>
                      <LayoutFlowItem>
                        <LayoutText align="center" lineHeight="$m">
                          {card?.content}
                        </LayoutText>
                      </LayoutFlowItem>
                    </LayoutFlow>
                  </LayoutContainer>
                </Card>
              ))}
            </Flex>
          </Section>
        </Flex>
      </Flex>

      <Flex
        css={{
          backgroundColor: '$skin-white-smoke',
          flexDirection: 'column',
          paddingBlock: '$spacing-5x',
          paddingInline: '$spacingMainMobile',
          '@tabletAndUp': {
            paddingBlock: '$spacing-7x',
          },
        }}
      >
        <Flex
          css={{
            flexDirection: 'column',
            maxWidth: '$textContent',
            margin: 'auto',
          }}
        >
          <MarkdownWithTypo content={data.main.windowText} />
        </Flex>
      </Flex>

      <ContentCenter14
        content={data.main?.priceText}
        label={data.main?.priceLink?.label}
        href={data.main?.priceLink?.href}
      />

      <Flex
        css={{
          backgroundColor: '$skin-white',
          flexDirection: 'column',
          paddingBlock: '$spacing-5x',
          paddingInline: '$spacingMainMobile',
          '@tabletAndUp': {
            paddingBlock: '$spacing-7x',
          },
        }}
      >
        <Flex
          css={{
            flexDirection: 'column',
            maxWidth: '$laptop',
            margin: 'auto',
          }}
        >
          <LayoutIcons>
            {data.main.logos?.map((logo, index) => (
              <LayoutImage
                key={index}
                objectFit={'contain'}
                imageAlt={logo?.imageAlt}
                imageUrl={logo?.imageSrc}
                sizes={'(max-width: 34.375rem) 25vw, 15vw'}
              />
            ))}
          </LayoutIcons>
          <Spacer size="$spacing-7x" />
          <LayoutFlow
            alignItems="center"
            gap="$spacing-7x"
            wrap={{ '@mobileOnly': 'wrapReverse' }}
          >
            <LayoutFlowItem size="5of12">
              <MarkdownWithTypo content={data.main.aboutMe?.content} />
              <Spacer size="$spacing-4x" />
              <Link href={data.main.aboutMe?.buttonHref ?? '/ueber-mich'}>
                <Button>{data.main.aboutMe?.buttonLabel}</Button>
              </Link>
            </LayoutFlowItem>
            <LayoutFlowItem size="7of12">
              <LayoutImage
                aspectRatio={60 / 43}
                imageAlt={data.main.aboutMe?.imageAlt}
                imageUrl={data.main.aboutMe?.imageSrc}
                imagePosition={data.main.aboutMe?.imagePosition}
              />
            </LayoutFlowItem>
          </LayoutFlow>
        </Flex>
      </Flex>
    </>
  );
}

export const getStaticProps = async () => {
  const pageResponse = await client.queries.main({ relativePath: 'index.mdx' });

  return {
    props: {
      data: pageResponse.data,
      query: pageResponse.query,
      variables: pageResponse.variables,
    },
  };
};
