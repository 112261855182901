import { styled } from '../../../stitches.config';

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: '$spacing-5x',
  height: '80px',
  minHeight: '80px',
  '@tabletAndUp': {
    justifyContent: 'flex-start',
  },
});
