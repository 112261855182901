import { styled } from '../../../stitches.config';

export const Container = styled('div', {
  variants: {
    size: {
      '5of12': {
        '@exceptMobile': {
          width: 'calc(500%/12)',
        },
      },
      '7of12': {
        '@exceptMobile': {
          width: 'calc(700%/12)',
        },
      },
      full: {
        width: '100%',
      },
      half: {
        '@exceptMobile': {
          width: '50%',
        },
      },
      third: {
        '@exceptMobile': {
          width: 'calc(100% / 3)',
        },
      },
      fourth: {
        '@exceptMobile': {
          width: '25%',
        },
      },
    },
  },
  width: '100%',
});
