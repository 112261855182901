import { styled } from '../../../stitches.config';

export const Container = styled('div', {
  boxShadow: '$shadow-1',
});

export const ContainerAnchor = styled('a', {
  backgroundColor: '$skin-white',
  boxShadow: '$shadow-1',
  cursor: 'pointer',
  display: 'block',
  transition: '$shadow-hover',
  height: 'auto',
  '&:hover': {
    boxShadow: '$shadow-2',
  },
});
