import { Container, ContainerAnchor } from './Card.styles';
import { ReactNode, memo } from 'react';
import Link from 'next/link';

function ContainerLinkPureComponent({
  children,
  linkUrl,
  linkUrlLang,
  scroll = true,
}: {
  children: ReactNode;
  linkUrl: string;
  linkUrlLang?: string;
  scroll?: boolean | null;
}) {
  const external = linkUrl.includes('www.') || linkUrl.includes('http');

  return (
    <Link
      href={linkUrl}
      hrefLang={linkUrlLang}
      scroll={scroll ? scroll : true}
      passHref
    >
      <ContainerAnchor target={external ? '_blank' : '_self'}>
        {children}
      </ContainerAnchor>
    </Link>
  );
}

const ContainerLink = memo(ContainerLinkPureComponent);

function CardPureComponent({
  children,
  linkUrl,
  linkUrlLang,
  scroll = true,
}: {
  children?: ReactNode;
  linkUrl?: string | null;
  linkUrlLang?: string | null;
  scroll?: boolean | null;
}) {
  if (linkUrl) {
    return (
      <ContainerLink
        linkUrl={linkUrl}
        linkUrlLang={linkUrlLang ?? undefined}
        scroll={scroll}
      >
        {children}
      </ContainerLink>
    );
  } else {
    return <Container>{children}</Container>;
  }
}

export const Card = memo(CardPureComponent);
